import React from 'react';
import Home from "./components/home/Home";


function App() {
  return (
  <Home></Home>
  );
}

export default App;
